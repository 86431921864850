window.wait = function(mode)
{
	if(!$('#wait').length)
	{
		$('body').prepend("<div id='wait' style='position:fixed; z-index: 1000; text-align:center; font-size: 30px; top:0; left:0; width:100%; height: 100%; display: none; opacity: 0.8; background-color: #fff; padding-top: 10%; '>Загрузка...</div>");
	}


	switch(mode)
	{
		case "on":
			$('#wait').show().off('click').click(function(){$(this).hide()});
		break;
		case "off":
			$('#wait').hide();
		break;
	}
}

window.addToBasket = function(good_id, params)
{

	$p = {
		'windowMode' : 'alert'
		};
	if(!params)
        params = {};

	if($global && $global.windowMode)
	{
		$p.windowMode = $global.windowMode;
	}
	wait('on');

	var reqData =  Object.assign({}, {
        do: 'basket',
        action: 'addToBasket',
        good_id: good_id,
        _location: document.location.href
    }, params);


	$.ajax({
		url: $global.mainUrl,
		type : 'POST',
		data : reqData,
		dataType : "json",
		success: function (data, textStatus) {
			if(!data)	data	=	{};
			if(data == null)	data	=	{};


			wait('off');

			if(!data.status) data.status	= 'error';
			if(!data.data) data.data	= '';

			switch(data.status)
			{
				case "error":

					switch($p.windowMode)
					{
						case	"modal":
								 modalWindow({
									'id'		:	good_id,
									'title'		:	'Произошла ошибка',
									'content'	:	data.data
								});
						break;
						case	"alert":
							alert("Ошибка! "+data.data);

						break;
					}

				break;
				case	"ok":
                 	if(data && data.post)
					{
						if(!data.post.filters)
                            data.post.filters = {};
                        if(!data.post.filters.price)
                            data.post.filters.price	=	"Не указана";
                        else
                            data.post.filters.price	=	parseFloat(data.post.filters.price);

                        switch($p.windowMode)
                        {
                            case	"modal":

                                modalWindow({
                                    'id'		:	good_id,
                                    'title'		:	'Товар добавлен в Корзину',
                                    'content'	:	'<div class="modalItem "><div class="image"><img src="'+data.post.images.main.url+'"></div><div class="information"><div class="title"><a href="'+data.post.url+'">'+data.post.title+'</a></div><div class="description"><span>Цена:</span> '+data.post.filters.price+'</div></div><div class="clearfix"></div></div><div align="right"><a href="/basket/">Перейти в корзину</a></div>'
                                });

                                break;
                            case	"alert":
                                alert("Товар добавлен в корзину");

                                break;
                        }

                        if(typeof $global.basket != 'undefined')
                        {
                            if(typeof $global.basket.selector != 'undefined')
                            {
                                if(typeof $global.basket.selector.basketSize != 'undefined')
                                {
                                    $($global.basket.selector.basketSize).html(parseInt($($global.basket.selector.basketSize).html())+1);
                                }
                                if(typeof $global.basket.selector.basketSum	!= 'undefined')
                                {
                                    $($global.basket.selector.basketSum).html(parseFloat($($global.basket.selector.basketSum).html())+parseFloat(data.post.filters.price));
                                }
                            }
                        }
					}

				break;
			}
		}
	});
}


window.modalWindow = function ($d)
{
	if($d && $d.id)
	{
		if(!$d.title)	$d.title	=	"";
		if(!$d.content)	$d.content	=	"";
		var $p =
		{
			'rezult':'',
			'id'	:	'modal'+$d.id,

		}
		$p.rezult	=
		'<div id="'+$p.id+'" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">'+
		  '<div class="modal-dialog">'+
			'<div class="modal-content">'+
				 ' <div class="modal-header">'+
					'<button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>'+
					'<h4 class="modal-title" >'+$d.title+'</h4>'+
				  '</div>'+
				  '<div class="modal-body">'+
			 			$d.content	+
			  	  '</div>'+
				  '<div class="modal-footer">'+
					'<button type="button" class="btn btn-default modalOkBtn" data-dismiss="modal">OK</button>'+

				  '</div>'+


			'</div>'+
		  '</div>'+
		'</div>';

		$('body').prepend($p.rezult)

        if(typeof $d.okCallback != 'undefined')
        {
            $("#"+$p.id).find('.modalOkBtn').off('click').on('click',function(){
                $d.okCallback();

            });
        }

        if(typeof $d.init != 'undefined') {
            $d.init($("#"+$p.id));
        }

		$("#"+$p.id).modal('show');



		}
}




$( document ).ready(function(){
    $('.goodCountMinus').click(function(){
        var $p	=	{
            'inputElement'	:	$(this).next('.goodsCountInput'),
            'nowCount'		:	parseInt($(this).next('.goodsCountInput').val())
        }
        if($p.nowCount>0)	$p.nowCount--;
        else	$p.nowCount	=	0;
        $p.inputElement.val($p.nowCount);
    });
    $('.goodCountPlus').click(function(){
        var $p	=	{
            'inputElement'	:	$(this).prev('.goodsCountInput'),
            'nowCount'		:	parseInt($(this).prev('.goodsCountInput').val())
        }
        $p.nowCount++;

        $p.inputElement.val($p.nowCount);
    });

    $('#showHideOrderForm').off('click').click(function(){
			$('#orderForm').toggle('showOrHide', function () {
				$('html,body').animate({
					scrollTop: $(".orderBlock").offset().top
				}, 1000);
			});




        return false;
    });

    $('#submitOrder').off('click').click(function(){
        $('#orderForm').submit();
    });

    $('.deliveryButton').off('click').click(function(){

    	var $delivery = $('#orderForm #order_delivery');
        if($(this).is(':checked'))
        {
			$delivery.val(1);
            $('.deliveryInfo').show();
        }
        else
        {
			$delivery.val(0);
            $('.deliveryInfo').hide();
        }
    });

    $('.submitBasketForm').off('click').click(function(){
        $('#basketForm').submit();
    });
});