$(function() {
 $.fn.scrollToTop = function() {
 $(this).hide().removeAttr("href");
 if ($(window).scrollTop() >= "250") $(this).fadeIn("slow")
 var scrollDiv = $(this);
 $(window).scroll(function() {
 if ($(window).scrollTop() <= "250") $(scrollDiv).fadeOut("slow")
 else $(scrollDiv).fadeIn("slow")
 });
 $(this).click(function() {
 $("html, body").animate({scrollTop: 0}, "slow")
 })
 }
});

$(function() {

    var inited = {};
    $('[data-show]').each(function(i, el){
        var elname = $(el).attr('data-show');
        var group = $(el).attr('data-group');

        if(!inited[group]){
            inited[group] = true;
            if(group){
                $("[data-group='" + group + "'][data-name]").addClass('hidden');
            }
        }


        if( $(el).hasClass('active') ){
            if(elname == '_ALL'){
                $("[data-group='" + group + "'][data-name]").removeClass('hidden');
            }
            else{
                $("[data-group='" + group + "'][data-name='" + elname + "']").removeClass('hidden');
            }
        }

        $(el).click(function () {
            $("[data-group='" + group + "'][data-show]").removeClass('active');


            $("[data-group='" + group + "'][data-name]").addClass('hidden');

            $(this).addClass('active');
            if(elname == '_ALL'){
                $("[data-group='" + group + "'][data-name]").removeClass('hidden');
            }
            else{
                $("[data-group='" + group + "'][data-name='" + elname + "']").removeClass('hidden');
            }


        });
    });

    $('[data-toggle]').click(function(){
        $(this).toggleClass('active');
        var name = $(this).attr('data-toggle');
        $("[data-toogle-name='" + name + "']").toggleClass('toogleHidden');
    });

    $('[data-class]').click(function(){
        var name = $(this).attr('data-to');
        var element = $("[data-name='" + name + "']");
        $("[data-to='" + name + "'][data-class]").removeClass('active');



        $("[data-to='" + name + "']").each(function (i, el) {
            element.removeClass( $(el).attr('data-class') );
        });
        element.addClass( $(this).attr('data-class')  );
        $(this).addClass('active');
    });


    /**
     * Предложение цены
     */
    $('.offerPrice').click(function(){

    });



    $('.fullImages a').attr('data-fancybox', 'fullimages').fancybox({
        protect: true
    });
    $('.realImages a').attr('data-fancybox', 'realImages').fancybox({
        protect: true
    });


    $("#Go_Top").scrollToTop();



    $('input[name="phone"]').mask('0 (000) 000-00-00');
    $('input[name="email"]').mask("A", {
        translation: {
            "A": { pattern: /[\w@\-.+]/, recursive: true }
        }
    });
    $('input.floatValue').mask("A", {
        translation: {
            "A": { pattern: /[\d,]/, recursive: true }
        }
    });


});


$(function() {
	$('.header-controls > .login-btn').on('click', function () {
        $(this).parents('.header-controls').find('.login_block').toggle();
        return false;
    });
    $(document).on('click', function(e) {

		if (!$(e.target).parents().hasClass('header-controls')) {
			$('.login_block').hide();
		}
	});
	
		$('.nav_btn').on('click', function () {
        $(this).parents('.headmenu').find('ul.main_nav').toggle();
        return false;
    });


	$(document).on('click', function(e) {

		 if (!$(e.target).parents().hasClass('.headmenu') && $('body').width() < 980) {
			$('.headmenu ul.main_nav').hide();
		}
		
	});
});