require(defaultTpl + 'js/basket.js');
require("@fancyapps/fancybox");
import 'lazysizes';
import 'jquery-mask-plugin';
import Vue from 'vue';

window.Vue = Vue;

require(tplCfgDir + "css/style.css");
require(tplCfgDir + "css/engine.css");
require(tplCfgDir + "css/responsive.css");

require('@fancyapps/fancybox/dist/jquery.fancybox.css');




require(tplCfgDir + 'js/script.js');